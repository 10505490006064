import { detectMob, isTruthy } from 'utility/functions';
import { useCallback, useEffect } from 'react';

import { AuthError } from 'types/errors';
import { AuthErrorType } from 'types/login';
import { IconsEnum } from 'components/Icons';
import { isHappybetSite } from 'utility/constant';
import { mutex } from 'components/refreshTokenEngine';
import { setAlertMessage } from 'features/dashboard/dashboardSlice';
import { signIn } from 'next-auth/react';
import { useAppDispatch } from 'lib/centralStore';
import { useGetLabelsByKeys } from 'hooks/useLingUI';
import { useIsSeamlessMode } from 'hooks';
import { useRouter } from 'next/router';

export const SeamlessLoginComponent = () => {
  const router = useRouter();
  const seamlessMode = useIsSeamlessMode();
  const dispatch = useAppDispatch();
  const [blockedAccountLoginError, usernamePasswordError] = useGetLabelsByKeys([
    'blocked-account-login-error',
    'token-expired-error',
  ]);

  const authenticate = useCallback(
    async (username, password, clientType) => {
      try {
        // lock other api calls during the login intervall
        await mutex.acquire();
      } catch (ex) {
        console.log('mutex-exc', ex);
      }

      const response = await signIn('credentials', {
        redirect: false,
        username,
        password,
        frontendType: detectMob() ? '60' : '1',
        clientType,
        seamless: seamlessMode,
        fromOldSite: true,
        lang: isHappybetSite ? router.locale?.replace('_AT', '') : undefined,
      });
      const isSuccess = isTruthy(response?.ok);

      if (isSuccess) {
        // await an arbitrary delay to allow new session data to be loaded
        await new Promise((resolve) => setTimeout(resolve, 499));
      } else {
        const { data } = JSON.parse(response?.error!) as AuthError;
        dispatch(
          setAlertMessage({
            icon: IconsEnum.CLOSE_CIRCLE,
            message:
              data.message === AuthErrorType.InvalidGrant
                ? usernamePasswordError
                : data.message === AuthErrorType.BlockedAccount
                ? blockedAccountLoginError
                : data.message,
            color: 'var(--color-warning)',
          })
        );
      }

      try {
        // unlock other api calls during the login intervall
        await mutex.release();
      } catch (ex) {
        console.log('mutex-exc', ex);
      }

      if (isSuccess) {
        const path = router.asPath.split('?')[0];
        if (seamlessMode) router.push(`${path}?seamless`);
        else router.push(path);
      }
      return response;
    },
    [router, dispatch, blockedAccountLoginError, usernamePasswordError, seamlessMode]
  );

  useEffect(() => {
    const username = router.query['snai-carta'];
    const password = router.query['snai-token'];
    const clientType = router.query['client-type'];

    if (username && password && clientType) {
      authenticate(username, password, clientType);
    }
  }, [router.query, router.asPath, dispatch, authenticate]);

  return null;
};
